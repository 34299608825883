.drop-zone {
    /* position: fixed;
    inset: 0;
    opacity: 0; */
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .drop-zone.dragging {
    border-color: #3498db;
  }
  
  .drop-zone-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .file-input {
    display: none;
  }